import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
import { TwitterIcon, TelegramIcon, InstagramIcon, DiscordIcon, MediumIcon, FacebookIcon, YoutubeIcon } from "../Svg";
export var footerLinks = [
    {
        label: "About",
        items: [
            {
                label: "Contact",
                href: "https://docs.pancakeswap.finance/contact-us"
            },
            {
                label: "Blog",
                href: "https://medium.com/pancakeswap"
            },
            {
                label: "Community",
                href: "https://docs.pancakeswap.finance/contact-us/telegram"
            },
            {
                label: "CAKE",
                href: "https://docs.pancakeswap.finance/tokenomics/cake"
            },
            {
                label: "\u2014"
            },
            {
                label: "Online Store",
                href: "https://pancakeswap.creator-spring.com/",
                isHighlighted: true
            }, 
        ]
    },
    {
        label: "Help",
        items: [
            {
                label: "Customer",
                href: "Support https://docs.pancakeswap.finance/contact-us/customer-support"
            },
            {
                label: "Troubleshooting",
                href: "https://docs.pancakeswap.finance/help/troubleshooting"
            },
            {
                label: "Guides",
                href: "https://docs.pancakeswap.finance/get-started"
            }, 
        ]
    },
    {
        label: "Developers",
        items: [
            {
                label: "Github",
                href: "https://github.com/pancakeswap"
            },
            {
                label: "Documentation",
                href: "https://docs.pancakeswap.finance"
            },
            {
                label: "Bug Bounty",
                href: "https://app.gitbook.com/@pancakeswap-1/s/pancakeswap/code/bug-bounty"
            },
            {
                label: "Audits",
                href: "https://docs.pancakeswap.finance/help/faq#is-pancakeswap-safe-has-pancakeswap-been-audited"
            },
            {
                label: "Careers",
                href: "https://docs.pancakeswap.finance/hiring/become-a-chef"
            }, 
        ]
    }, 
];
export var socials = [
    {
        label: "Twitter",
        icon: TwitterIcon,
        href: "https://twitter.com/bitnouofficial"
    },
    {
        label: "Facebook",
        icon: FacebookIcon,
        href: "https://www.facebook.com/Bitnouofficial/"
    },
    {
        label: "Youbube",
        icon: YoutubeIcon,
        href: "https://www.youtube.com/c/BITNOUOfficial_English"
    },
    {
        label: "Telegram",
        icon: TelegramIcon,
        items: [
            {
                label: "English",
                href: "https://t.me/bitnouofficial_english"
            },
            // {
            //   label: "Bahasa Indonesia",
            //   href: "https://t.me/PancakeSwapIndonesia",
            // },
            // {
            //   label: "中文",
            //   href: "https://t.me/PancakeSwap_CN",
            // },
            // {
            //   label: "Tiếng Việt",
            //   href: "https://t.me/PancakeSwapVN",
            // },
            // {
            //   label: "Italiano",
            //   href: "https://t.me/pancakeswap_ita",
            // },
            // {
            //   label: "русский",
            //   href: "https://t.me/pancakeswap_ru",
            // },
            // {
            //   label: "Türkiye",
            //   href: "https://t.me/pancakeswapturkiye",
            // },
            // {
            //   label: "Português",
            //   href: "https://t.me/PancakeSwapPortuguese",
            // },
            {
                label: "Espa\xf1ol",
                href: "https://t.me/bitnouofficial_Spanish"
            },
            // {
            //   label: "日本語",
            //   href: "https://t.me/pancakeswapjp",
            // },
            {
                label: "Fran\xe7ais",
                href: "https://t.me/bitnouofficial_French"
            },
            // {
            //   label: "Deutsch",
            //   href: "https://t.me/PancakeSwap_DE",
            // },
            // {
            //   label: "Filipino",
            //   href: "https://t.me/Pancakeswap_Ph",
            // },
            // {
            //   label: "ქართული ენა",
            //   href: "https://t.me/PancakeSwapGeorgia",
            // },
            {
                label: "Krey\xf2l",
                href: "https://t.me/bitnouofficial_Creole"
            },
            {
                label: "News",
                href: "https://t.me/bitnouofficial_news"
            }, 
        ]
    },
    // {
    //   label: "Reddit",
    //   icon: RedditIcon,
    //   href: "https://reddit.com/r/pancakeswap",
    // },
    {
        label: "Instagram",
        icon: InstagramIcon,
        href: "https://www.instagram.com/bitnouofficial/"
    },
    // {
    //   label: "Github",
    //   icon: GithubIcon,
    //   href: "https://github.com/pancakeswap/",
    // },
    {
        label: "Discord",
        icon: DiscordIcon,
        href: "https://discord.gg/5Qb4bM7zYA"
    },
    {
        label: "Medium",
        icon: MediumIcon,
        href: "https://medium.com/@bitnouofficial"
    }, 
];
export var langs = _to_consumable_array(Array(20)).map(function(_, i) {
    return {
        code: "en".concat(i),
        language: "English".concat(i),
        locale: "Locale".concat(i)
    };
});
