import { useState, useCallback } from 'react'
import {
  ModalContainer,
  ModalBody,
  Text,
  Button,
  Flex,
  InjectedModalProps,
  Checkbox,
  ModalHeader,
  ModalTitle,
  Heading,
  Box,
} from '@pancakeswap/uikit'
import { useTranslation } from '@pancakeswap/localization'
import styled from 'styled-components'
import { InjectedProps } from '@pancakeswap/uikit/src/widgets/Modal/types'

const GradientModalHeader = styled(ModalHeader)`
  background: ${({ theme }) => theme.colors.gradients.bubblegum};
  padding-bottom: 24px;
  padding-top: 24px;
`

const PerpetualModal: React.FC<InjectedProps> = ({onDismiss}) => {
  const [checkState, setCheckState] = useState(false)
  const { t } = useTranslation()

  return (
    <ModalContainer title={t('Perpetual Trading Confirmation')} $minWidth="320px">
      <GradientModalHeader>
        <ModalTitle>
          <Heading scale="lg">{t('Perpetual Trading Confirmation')}</Heading>
        </ModalTitle>
      </GradientModalHeader>
      <ModalBody p="24px" maxWidth={['100%', '100%', '100%', '600px']}>
        <Box maxHeight="400px" overflowY="auto">
          <Text as="p" color="textSubtle" mb="24px">
            Please note that the Perpetual Trading feature involves futures trading. If you are not fully acquainted with the process, 
            we strongly advise against participating in futures trading.<br/><br/>
            Kindly be informed that Bitnouswap Perpetual Trading is facilitated by a third-party entity, ApolloX. By utilizing this feature, 
            you acknowledge and agree that the entire responsibility rests with you, as the user, and the third-party service provider, ApolloX. 
            Bitnouswap disclaims any responsibility and does not accept claims from users.<br/><br/>

            For a comprehensive understanding of the terms and conditions associated with using this feature, 
            we highly recommend a thorough review of the ApolloX&nbsp;
            <a href="https://apollox-finance.gitbook.io/apollox-finance/about-us/privacy-policy/terms-of-use" target="_blank" rel="noreferrer"
            style={{ color: "blue", textDecoration: "underline"}}>Terms of Use.</a>
          </Text>
          <>{ /* eslint-disable-next-line jsx-a11y/label-has-associated-control */ }</>
          <label
            key="agree_perpetual"
            htmlFor="agree_perpetual"
            style={{ display: 'block', cursor: 'pointer', marginBottom: '24px' }}
          >
            <Flex alignItems="center">
              <div style={{ flex: 'none', alignSelf: 'flex-start', paddingTop: '8px' }}>
                <Checkbox
                  id="agree_perpetual"
                  scale="sm"
                  checked={checkState}
                  onChange={() => setCheckState(!checkState)}
                />
              </div>
              <Text ml="8px">I have read and agreed to the&nbsp;
                <a href="https://apollox-finance.gitbook.io/apollox-finance/about-us/privacy-policy/terms-of-use" target="_blank" rel="noreferrer"
                  style={{ color: "blue", textDecoration: "underline"}}>terms</a>.</Text>
            </Flex>
          </label>
        </Box>
        <Button
          width="100%"
          onClick={()=>{
            localStorage.setItem("already-shown-perp-modal", "true")
            window.open('https://perp.bitnou.com', '_blank', 'noopener, noreferrer');
            onDismiss();
          }}
          disabled={!checkState}
        >
          {t('Continue')}
        </Button>
      </ModalBody>
    </ModalContainer>
  )
}

export default PerpetualModal
