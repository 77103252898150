import {
  MenuItemsType,
  DropdownMenuItemType,
  SwapIcon,
  SwapFillIcon,
  EarnFillIcon,
  EarnIcon,
  TrophyIcon,
  TrophyFillIcon,
  NftIcon,
  NftFillIcon,
  MoreIcon,
  CommunityIcon,
  CommunityFilledIcon,
  SellIcon,
  ChartIcon,
  Coin98Icon,
  CoinbaseWalletIcon,
  Cards,
  CogIcon,
  CurrencyIcon,
} from '@pancakeswap/uikit'
import { ContextApi } from '@pancakeswap/localization'
import { nftsBaseUrl } from 'views/Nft/market/constants'
import { perpLangMap } from 'utils/getPerpetualLanguageCode'
import { perpTheme } from 'utils/getPerpetualTheme'
import { DropdownMenuItems } from '@pancakeswap/uikit/src/components/DropdownMenu/types'
import { ChainId } from '@pancakeswap/sdk'
import { status } from '@pancakeswap/uikit/src/widgets/Menu/config'

export type ConfigMenuDropDownItemsType = DropdownMenuItems & { hideSubNav?: boolean }
export type ConfigMenuItemsType = Omit<MenuItemsType, 'items'> & { hideSubNav?: boolean } & {
  items?: ConfigMenuDropDownItemsType[]
}

const filterItemBySupportChainId = (item, chainId) => {
  return !chainId || !item.supportChainIds ? true : item.supportChainIds?.includes(chainId)
}

const config: (
  t: ContextApi['t'],
  isDark: boolean,
  languageCode?: string,
  chainId?: number,
  isAlreadyShownPerpModal?: boolean
) => ConfigMenuItemsType[] = (t, isDark, languageCode, chainId, isAlreadyShownPerpModal) =>
  [
    // {
    //   label: t('Presale'),
    //   href: '/presale',
    //   icon: ChartIcon,
    //   fillIcon: ChartIcon,
    //   showItemsOnMobile: false,
    //   items: [],
    // },
    {
      label: t('Trade'),
      icon: SwapIcon,
      fillIcon: SwapFillIcon,
      href: '/swap',
      showItemsOnMobile: false,
      items: [
        {
          label: t('Swap'),
          href: '/swap',
        },
        {
          label: t('Limit'),
          href: '/limit-orders',
          supportChainIds: [ChainId.BSC],
        },
        {
          label: t('Liquidity'),
          href: '/liquidity',
        },
        {
          label: t('Perpetual'),
          href: isAlreadyShownPerpModal ? 'https://perp.bitnou.com': '#perp-modal',
          type: isAlreadyShownPerpModal ? DropdownMenuItemType.EXTERNAL_LINK : DropdownMenuItemType.INTERNAL_LINK,
        },
        // {
        //   label: t('Transfer'),
        //   href: '/transfer',
        // },
      ].filter((item) => filterItemBySupportChainId(item, chainId)),
    },
    {
      label: t('Earn'),
      href: '/farms',
      icon: EarnIcon,
      fillIcon: EarnFillIcon,
      supportChainIds: [ChainId.BSC],
      items: [
        {
          label: t('Farms'),
          href: '/farms',
        },
        {
      label: t('Pools'),
      href: '/pools',
        },
      ],
    },
    // {
    //   label: t('Win'),
    //   href: '/prediction',
    //   icon: TrophyIcon,
    //   fillIcon: TrophyFillIcon,
    //   supportChainIds: [ChainId.BSC],
    //   items: [
    //     {
    //       label: t('Trading Competition'),
    //       href: '/competition',
    //       hideSubNav: true,
    //     },
    //     {
    //       label: t('Prediction (BETA)'),
    //       href: '/prediction',
    //     },
    //     {
    //       label: t('Lottery'),
    //       href: '/lottery',
    //     },
    //     {
    //       label: t('Pottery (BETA)'),
    //       href: '/pottery',
    //     },
    //   ],
    // },
    {
      label: t('NFT'),
      href: `${nftsBaseUrl}`,
      icon: NftIcon,
      fillIcon: NftFillIcon,
      supportChainIds: [ChainId.BSC],
      items: [
        {
          label: t(''),
          status: status.SOON,
        },
      ],
    },
    {
      label: t('Games'),
      href: '/games',
      icon: TrophyIcon,
      fillIcon: TrophyFillIcon,
      supportChainIds: [ChainId.BSC],
      items: [
        {
          label: t(''),
          status: status.SOON,
        },
      ],
    },
    {
      label: t('Launchpad'),
      href: '/launchpad',
      icon: CommunityIcon,
      fillIcon: CommunityFilledIcon,
      supportChainIds: [ChainId.BSC],
      items: [
        {
          label: t(''),
          status: status.SOON,
        },
      ],
    },
    {
      label: t('Store'),
      href: '/store',
      icon: SellIcon,
      fillIcon: SellIcon,
      showItemsOnMobile: false,
      supportChainIds: [ChainId.BSC],
      items: [],
    },
    {
      label: t('Ramp'),
      href: '/ramp',
      icon: CurrencyIcon,
      fillIcon: CurrencyIcon,
      showItemsOnMobile: false,
      supportChainIds: [ChainId.BSC],
      items: [],
    },
    // {
    //   label: t('NFT'),
    //   href: `${nftsBaseUrl}`,
    //   icon: NftIcon,
    //   fillIcon: NftFillIcon,
    //   supportChainIds: [ChainId.BSC],
    //   items: [
    //     {
    //       label: t('Overview'),
    //       href: `${nftsBaseUrl}`,
    //     },
    //     {
    //       label: t('Collections'),
    //       href: `${nftsBaseUrl}/collections`,
    //     },
    //     {
    //       label: t('Activity'),
    //       href: `${nftsBaseUrl}/activity`,
    //     },
    //   ],
    // },
    // {
    //   label: '',
    //   href: '/info',
    //   icon: MoreIcon,
    //   hideSubNav: true,
    //   supportChainIds: [ChainId.BSC],
    //   items: [
    //     {
    //       label: t('Info'),
    //       href: '/info',
    //     },
    //     {
    //       label: t('IFO'),
    //       href: '/ifo',
    //     },
    //     {
    //       label: t('Voting'),
    //       href: '/voting',
    //     },
    //     {
    //       type: DropdownMenuItemType.DIVIDER,
    //     },
    //     {
    //       label: t('Leaderboard'),
    //       href: '/teams',
    //     },
    //     {
    //       type: DropdownMenuItemType.DIVIDER,
    //     },
    //     {
    //       label: t('Blog'),
    //       href: 'https://medium.com/pancakeswap',
    //       type: DropdownMenuItemType.EXTERNAL_LINK,
    //     },
    //     {
    //       label: t('Docs'),
    //       href: 'https://docs.pancakeswap.finance',
    //       type: DropdownMenuItemType.EXTERNAL_LINK,
    //     },
    //   ],
    // },
  ].filter((item) => filterItemBySupportChainId(item, chainId))

export default config
