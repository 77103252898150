import { FooterLinkType } from '@pancakeswap/uikit'
import { ContextApi } from '@pancakeswap/localization'

export const footerLinks: (t: ContextApi['t']) => FooterLinkType[] = (t) => [
  {
    label: t('ABOUT'),
    items: [
      {
        label: t('About Us'),
        href: 'https://bitnou-official.gitbook.io/bitnouswap/about-bitnouswap',
        // isHighlighted: true,
      },
      {
        label: t('Whitepaper'),
        href: 'https://bitnou-official.gitbook.io/bitnouswap/whitepaper',
      },
      {
        label: t('Audits'),
        href: 'https://bitnou-official.gitbook.io/bitnouswap/audits-and-kyc-certificates',
      },
      {
        label: t('Community'),
        href: 'https://bitnou-official.gitbook.io/bitnouswap/crypto-dating-networking-community',
      },
      {
        label: t('Careers'),
        href: 'https://forms.gle/dSh4SGn5ye3gRRz57',
      }      
    ],
  },
  {
    label: t('SUPPORT'),
    items: [
      {
        label: t('Online store'),
        href: 'https://store.bitnou.com',
      },
      {
        label: t('Bitnou charity'),
        href: 'https://bitnou-official.gitbook.io/bitnouswap/charity-donations',
      },
      {
        label: t('Help'),
        href: 'https://bitnou-official.gitbook.io/bitnouswap/support-help',
      },
      {
        label: t('FAQs'),
        href: 'https://bitnou-official.gitbook.io/bitnouswap/faqs-youve-got-questions',
      },
      
      {
        label: t('Disclaimer'),
        href: 'https://bitnou-official.gitbook.io/bitnouswap/disclaimer',
      },
    ],
  },
  {
    label: t('ENGAGE'),
    items: [
      {
        label: t('Academy'),
        href: 'https://bitnou-official.gitbook.io/bitnouswap/crypto-learning-academy',
      },
      {
        label: t('Referral program'),
        href: 'https://bitnou-official.gitbook.io/bitnouswap/referrals',
      },
      {
        label: t('Partnership'),
        href: 'https://forms.gle/x5vTjzU9EaE9xPbo7',
      },
      {
        label: t('Marketing Cooperation'),
        href: 'https://bitnou-official.gitbook.io/bitnouswap/marketing-cooperation',
      },
      {
        label: t('Ambassador Program'),
        href: 'https://bitnou-official.gitbook.io/bitnouswap/ambassador-program',
      },
    ],
  },
]
